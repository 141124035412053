// Favicon manager
//
// Manages light and dark favicon variations, and handles status,
// showing a ✕, check, or pending dot over depending on the page status

// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {SOFT_NAV_STATE} from '@github-ui/soft-nav/states'
import {getColorMode} from '../color-modes'

let oldHref: string

export function updateFavicon(newHref?: string) {
  const colorScheme = getColorMode()
  const colorSchemeSuffix = colorScheme === 'light' ? '' : '-dark'
  const favicon = document.querySelector<HTMLLinkElement>('.js-site-favicon[type="image/svg+xml"]')
  const faviconFallback = document.querySelector<HTMLLinkElement>('.js-site-favicon[type="image/png"]')

  if (favicon && faviconFallback) {
    if (oldHref == null) {
      oldHref = favicon.href
    }

    if (newHref) {
      newHref = newHref.substr(0, newHref.lastIndexOf('.'))
      favicon.href = encodeURI(`${newHref}${colorSchemeSuffix}.svg`)
      faviconFallback.href = encodeURI(`${newHref}${colorSchemeSuffix}.png`)
    } else {
      const n = favicon.href.indexOf('-dark.svg')
      const newFaviconName = favicon.href.substr(0, n !== -1 ? n : favicon.href.lastIndexOf('.'))
      favicon.href = encodeURI(`${newFaviconName}${colorSchemeSuffix}.svg`)
      faviconFallback.href = encodeURI(`${newFaviconName}${colorSchemeSuffix}.png`)
    }
  }
}

function resetIcon() {
  updateFavicon()
}

observe('[data-favicon-override]', {
  add(el) {
    let href = el.getAttribute('data-favicon-override')!
    href = encodeURI(href)
    // Delay to allow `remove` for the old element to fire first
    setTimeout(() => updateFavicon(href))
  },
  remove() {
    resetIcon()
  },
})

updateFavicon()

window.matchMedia('(prefers-color-scheme: dark)').addListener(() => updateFavicon())
window.matchMedia('(prefers-color-scheme: light)').addListener(() => updateFavicon())

document.addEventListener(SOFT_NAV_STATE.SUCCESS, () => updateFavicon())
